import { TooltipBar } from "app/common/helper/TooltipBar";
import { NavLink, useLocation } from "react-router-dom";
import { checkIsActive } from "../../../common/helper/RouterHelper";
import { asideMenus } from './aside-menu/asideMenuData'
import { useSelector } from 'react-redux'
import {useEffect, useState} from "react";

function Aside({ showAsideMenu }) {
  const location = useLocation();
  const profile = useSelector((state => state.auth.profile));
  const asideMenusArray = asideMenus(profile);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };
  const [openMenuIndex, setOpenMenuIndex] = useState(null);

  const toggleDropdown = (index) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };

  const getWidthAsideMenu = () => {
      if (showAsideMenu) {
        return "300px";
      } else {
        return "100px";
      }
  };

  const updateActiveMenu = () => {
    const activeIndex = asideMenusArray.findIndex(
        (menuItem) =>
            getMenuItemActive(menuItem.path, menuItem?.children) && menuItem.children
    );
    // if (activeIndex !== -1) {
    //   setOpenMenuIndex(activeIndex);
    // }
  }
  useEffect(() => {
    updateActiveMenu();
  }, [asideMenusArray, location])
  return (
      <div
          className="aside aside-wrapper d-flex flex-column justify-content-between"
          style={{ width: getWidthAsideMenu() }}
      >
        <ul className="aside-menu p-0">
          {asideMenusArray.map((asideMenuItem, index) => {
            const isActive = getMenuItemActive(asideMenuItem.path, asideMenuItem.children);
            return (
              <li
                  className={`${
                      isActive ? "menu-item-active" : ""
                  } aside-menu-item`}
                  key={index}
              >
                {!showAsideMenu ? (
                        <>
                          <TooltipBar title={asideMenuItem.label} placement="right">
                            <NavLink
                                to={asideMenuItem.path}
                                className={`w-100 h-100 d-flex align-items-center justify-content-center`}
                            >
                              <img
                                  src={
                                    getMenuItemActive(asideMenuItem.path)
                                        ? asideMenuItem.iconActive
                                        : asideMenuItem.icon
                                  }
                                  alt="menu"
                              />
                            </NavLink>
                          </TooltipBar>
                        </>
                    ) : (<>
                      {asideMenuItem.children ? (
                          <div
                              className="d-flex align-items-center w-100 aside-menu-parent"
                              onClick={() => toggleDropdown(index)}
                          >
                            <img
                                src={
                                  isActive
                                      ? asideMenuItem.iconActive
                                      : asideMenuItem.icon
                                }
                                alt="menu"
                                className="mr-4"
                            />
                            <span>{asideMenuItem.label}</span>
                            <span className="dropdown-icon ml-auto">▼</span>
                          </div>
                      ) : (
                          <NavLink
                              to={asideMenuItem.path}
                              className="w-100 h-100 d-flex align-items-center"
                          >
                            <img
                                src={
                                  isActive
                                      ? asideMenuItem.iconActive
                                      : asideMenuItem.icon
                                }
                                alt="menu"
                                className="mr-4"
                            />
                            <span>{asideMenuItem.label}</span>
                          </NavLink>
                      )}
                    </>
                )}

                {showAsideMenu && asideMenuItem.children && openMenuIndex === index && (
                    <ul className="submenu p-0 ml-4">
                      {asideMenuItem.children.map((childItem, childIndex) => {
                        const isSubMenuItemActive = getMenuItemActive(childItem.path);
                        return (
                          <li className={`${isSubMenuItemActive ? "submenu-item-active" : ""} aside-submenu-item`} key={childIndex}>
                            <NavLink
                                to={childItem.path}
                                className="w-100 h-100 d-flex align-items-center"
                            >
                              <span className="ml-4">{childItem.label}</span>
                            </NavLink>
                          </li>
                      )})}
                    </ul>
                )}
              </li>
          )})}
        </ul>
      </div>
  );
}

export default Aside;
