import { dashBoardSlice } from "app/modules/Dashboard/redux/dashBoardSlice";
import { errorLogSlice } from "app/modules/ErrorLog/redux/errorLogSlice";
import { jobCrawlSlice } from "app/modules/JobCrawl/redux/jobCrawlSlice";
import { needPlantCareSlice } from "app/modules/NeedPlantCare/redux/needPlantCareSlice";
import { diagnoseSlice } from "app/modules/Diagnose/redux/diagnoseSlice";
import { diagnoseEditorSlice } from "app/modules/DiagnoseEditor/redux/diagnoseEditorSlice";
import { toxicEditorSlice } from "app/modules/ToxicEditor/redux/toxicSlice";

import { plantNotFoundSlice } from "app/modules/PlantNotFound/redux/plantNotFoundSlice";
import { proxySlice } from "app/modules/Proxy/redux/proxySlice";
import { deviceSlice } from "app/modules/Device/redux/deviceSlice";
import { totalPlantSlice } from "app/modules/TotalPlant/redux/totalPlantSlice";
import { combineReducers } from "redux";
// reducer
import { authSlice } from "./auth/authSlice";
import { wishListSlice } from "app/modules/WishList/redux/wishListSlice";
import { userSlice } from 'app/modules/Users/redux/userSlice'
import { feedbackSlice } from 'app/modules/Feedback/redux/feedbackSlice'
import { mushroomSlice } from 'app/modules/Mushroom/redux/mushroomSlice'
import { mushroomEditorSlice } from '../app/modules/MushroomEditor/redux/mushroomEditorSlice'
import { insectSlice } from 'app/modules/Insect/redux/insectSlice'
import { insectEditorSlice } from 'app/modules/InsectEditor/redux/insectEditorSlice'
import { faqSlice } from '../app/modules/FAQ/redux/faqSlice'
import { syncLogSlice } from "../app/modules/SyncLog/redux/syncLogSlice";
import { postSlice } from "../app/modules/Post/redux/postSlice";
import { categorySlice } from "../app/modules/Category/redux/categorySlice";

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  dashBoard: dashBoardSlice.reducer,
  totalPlant: totalPlantSlice.reducer,
  plantNotFound: plantNotFoundSlice.reducer,
  proxy: proxySlice.reducer,
  errorLog: errorLogSlice.reducer,
  jobCrawl: jobCrawlSlice.reducer,
  needPlantCare: needPlantCareSlice.reducer,
  wishList: wishListSlice.reducer,
  feedback: feedbackSlice.reducer,
  users: userSlice.reducer,
  device: deviceSlice.reducer,
  diagnose: diagnoseSlice.reducer,
  post: postSlice.reducer,
  category: categorySlice.reducer,
  diagnoseEditor: diagnoseEditorSlice.reducer,
  mushroom: mushroomSlice.reducer,
  mushroomEditor: mushroomEditorSlice.reducer,
  insect: insectSlice.reducer,
  insectEditor: insectEditorSlice.reducer,
  toxicEditor: toxicEditorSlice.reducer,
  faq: faqSlice.reducer,
  syncLog: syncLogSlice.reducer,
  common: {},
});
