import { logout } from "./auth/authActions";

export const setUpAxios = (axios, store) => {
  // Add a request interceptor
  axios.interceptors.request.use(
    function (request) {
      const {
        auth: { user },
      } = store.getState();

      // Add Authorization header if the user is authenticated
      if (user) {
        request.headers.Authorization = `Bearer ${user.access_token}`;
      }

      // Add 'is_admin=1' as a global request parameter
      if (!request.params) {
        request.params = {};
      }
      request.params.is_admin = 1;

      return request;
    },
    function (error) {
      // Handle request errors
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Handle successful responses (status codes 2xx)
      if (response.status === 500) {
        logout();
      }
      return response;
    },
    function (error) {
      // Handle response errors (status codes outside 2xx)
      return Promise.reject(error);
    }
  );
};
