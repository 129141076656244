import { lazy } from "react";

// ICON ASIDE MENU
import dashboardIcon from "../../../../common/assets/images/aside-menu/aside-menu-dashboard.png";
import chatIcon from "../../../../common/assets/images/aside-menu/aside-menu-chat.png";
import errorLogIcon from "../../../../common/assets/images/aside-menu/aside-menu-error-log.png";
import syncLogIcon from "../../../../common/assets/images/aside-menu/aside-menu-error-log.png";
import jobCrawlIcon from "../../../../common/assets/images/aside-menu/aside-menu-job-crawl.png";
import needPlantCareIcon from "../../../../common/assets/images/aside-menu/aside-menu-need-plant-care.png";
import toxicIcon from "../../../../common/assets/images/aside-menu/aside-menu-toxic-editor.png";
import diagnoseIcon from "../../../../common/assets/images/aside-menu/aside-menu-diagnose.png";
import postIcon from "../../../../common/assets/images/aside-menu/aside-menu-post.png";
import diagnoseEditorIcon from "../../../../common/assets/images/aside-menu/aside-menu-diagnose-editor.png";
import mushroomIcon from "../../../../common/assets/images/aside-menu/aside-menu-mushroom.png";
import mushroomEditorIcon from "../../../../common/assets/images/aside-menu/aside-menu-mushroom-editor.png";
import insectIcon from "../../../../common/assets/images/aside-menu/aside-menu-insect.png";
import insectEditorIcon from "../../../../common/assets/images/aside-menu/aside-menu-insect-editor.png";
import plantNotFoundIcon from "../../../../common/assets/images/aside-menu/aside-menu-plant-not-found.png";
import proxyIcon from "../../../../common/assets/images/aside-menu/aside-menu-proxy.png";
import totalPlantIcon from "../../../../common/assets/images/aside-menu/aside-menu-total-plant.png";
import totalStorageIcon from "../../../../common/assets/images/aside-menu/aside-menu-total-storage.png";
import usersIcon from "../../../../common/assets/images/aside-menu/aside-menu-users.png";
import editorIcon from "../../../../common/assets/images/aside-menu/aside-menu-editor.png";
import deviceIcon from "../../../../common/assets/images/aside-menu/aside-menu-device.png";
import wishListIcon from "../../../../common/assets/images/aside-menu/aside-menu-wish-list.png";
import feedbackIcon from "../../../../common/assets/images/aside-menu/aside-menu-feedback.png";

// ICON ASIDE MENU ACTIVE
import dashboardIconActive from "../../../../common/assets/images/aside-menu/aside-menu-dashboard-active.png";
import chatIconActive from "../../../../common/assets/images/aside-menu/aside-menu-faq-active.png";
import errorLogIconActive from "../../../../common/assets/images/aside-menu/aside-menu-error-log-active.png";
import syncLogIconActive from "../../../../common/assets/images/aside-menu/aside-menu-error-log-active.png";
import jobCrawlIconActive from "../../../../common/assets/images/aside-menu/aside-menu-job-crawl-active.png";
import needPlantCareIconActive from "../../../../common/assets/images/aside-menu/aside-menu-need-plant-active.png";
import toxicIconActive from "../../../../common/assets/images/aside-menu/aside-menu-toxic-editor-active.png";
import diagnoseIconActive from "../../../../common/assets/images/aside-menu/aside-menu-diagnose-active.png";
import postIconActive from "../../../../common/assets/images/aside-menu/aside-menu-post-active.png";
import diagnoseEditorIconActive from "../../../../common/assets/images/aside-menu/aside-menu-diagnose-editor-active.png";
import mushroomIconActive from "../../../../common/assets/images/aside-menu/aside-menu-mushroom-active.png";
import mushroomEditorIconActive from "../../../../common/assets/images/aside-menu/aside-menu-mushroom-editor-active.png";
import insectIconActive from "../../../../common/assets/images/aside-menu/aside-menu-insect-active.png";
import insectEditorIconActive from "../../../../common/assets/images/aside-menu/aside-menu-insect-editor-active.png";
import plantNotFoundIconActive from "../../../../common/assets/images/aside-menu/aside-menu-plant-not-found-active.png";
import proxyIconActive from "../../../../common/assets/images/aside-menu/aside-menu-proxy-active.png";
import totalPlantIconActive from "../../../../common/assets/images/aside-menu/aside-menu-total-plant-active.png";
import totalStorageIconActive from "../../../../common/assets/images/aside-menu/aside-menu-total-storage-active.png";
import usersIconActive from "../../../../common/assets/images/aside-menu/aside-menu-user-active.png";
import deviceIconActive from "../../../../common/assets/images/aside-menu/aside-menu-device-active.png";
import editorIconActive from "../../../../common/assets/images/aside-menu/aside-menu-editor-active.png";
import wishListIconActive from "../../../../common/assets/images/aside-menu/aside-menu-wish-list-active.png";
import feedbackIconActive from "../../../../common/assets/images/aside-menu/aside-menu-feedback-active.png";

const DashBoadPage = lazy(() => import("app/modules/Dashboard/page/DashboardPage"));
const FaqPage = lazy(() => import("app/modules/FAQ/page/FaqPage"));
const JobCrawlPage = lazy(() => import("app/modules/JobCrawl/page/JobCrawlPage"));
const NeedPlantCarePage = lazy(() => import("app/modules/NeedPlantCare/page/NeedPlantCarePage"));
const DiagnoseEditorPage = lazy(() => import("app/modules/DiagnoseEditor/page/DiagnoseEditorPage"));
const MushroomEditorPage = lazy(() => import("app/modules/MushroomEditor/page/MushroomEditorPage"));
const InsectEditorPage = lazy(() => import("app/modules/InsectEditor/page/InsectEditorPage"));
const ToxicEditorPage = lazy(() => import("app/modules/ToxicEditor/page/ToxicPage"));
const DiagnosePage = lazy(() => import("app/modules/Diagnose/page/DiagnosePage"));
const PostPage = lazy(() => import("app/modules/Post/page/PostPage"));
const CategoryPage = lazy(() => import("app/modules/Category/page/CategoryPage"));
const MushroomPage = lazy(() => import("app/modules/Mushroom/page/MushroomPage"));
const InsectPage = lazy(() => import("app/modules/Insect/page/InsectPage"));
const ProxyPage = lazy(() => import("app/modules/Proxy/page/ProxyPage"));
const TotalStoragePage = lazy(() => import("app/modules/TotalStorage/TotalStoragePage"));
const UsersPage = lazy(() => import("app/modules/Users/page/UsersPage"));
const DevicePage = lazy(() => import("app/modules/Device/page/DevicePage"));
const WishListPage = lazy(() => import("app/modules/WishList/page/WishListPage"));
const FeedbackPage = lazy(() => import("app/modules/Feedback/page/FeedbackPage"));
const TotalPlantPage = lazy(() => import("app/modules/TotalPlant/page/TotalPlantPage"));
const PlantNotFoundPage = lazy(() => import("app/modules/PlantNotFound/page/PlantNotFoundPage"));
const ErrorLogPage = lazy(() => import("app/modules/ErrorLog/page/ErrorLogPage"));
const EditorPage = lazy(() => import("app/modules/Editor/page/EditorPage"));
const SyncLogPage = lazy(() => import("app/modules/SyncLog/page/SyncLogPage"));

export const asideMenus = (profile) => {
  let menuList;
  menuList = [
    {
      icon: dashboardIcon,
      iconActive: dashboardIconActive,
      path: "/dashboard",
      label: "Dashboard",
      component: DashBoadPage,
    },
    {
      icon: totalPlantIcon,
      iconActive: totalPlantIconActive,
      path: "/total-plant/list",
      label: "Total Plant",
      component: TotalPlantPage,
    },
    {
      icon: plantNotFoundIcon,
      iconActive: plantNotFoundIconActive,
      path: "/plant-not-found",
      label: "Plant Not Found",
      component: PlantNotFoundPage,
    },
    {
      icon: needPlantCareIcon,
      iconActive: needPlantCareIconActive,
      path: "/need-plant-care",
      label: "Need Plant Care",
      component: NeedPlantCarePage,
    },
    {
      icon: toxicIcon,
      iconActive: toxicIconActive,
      path: "/toxic-editor",
      label: "Toxic Editor",
      component: ToxicEditorPage,
    },
    {
      icon: postIcon,
      iconActive: postIconActive,
      path: "/posts",
      label: "Posts",
      component: PostPage,
      children: [
        {
          path: "/posts",
          label: "Posts",
          component: PostPage,
        },
        {
          path: "/categories",
          label: "Category",
          component: CategoryPage,
        },
      ],
    },
    {
      icon: diagnoseIcon,
      iconActive: diagnoseIconActive,
      path: "/diagnose",
      label: "Diagnose",
      component: DiagnosePage,
      children: [
        {
          icon: diagnoseIcon,
          iconActive: diagnoseIconActive,
          path: "/diagnose",
          label: "List",
          component: DiagnosePage,
        },
        {
          icon: diagnoseEditorIcon,
          iconActive: diagnoseEditorIconActive,
          path: "/diagnose-editor",
          label: "Diagnose Editor",
          component: DiagnoseEditorPage,
        },
      ],
    },
    {
      icon: mushroomIcon,
      iconActive: mushroomIconActive,
      path: "/mushroom",
      label: "Mushroom",
      component: MushroomPage,
    },
    {
      icon: mushroomEditorIcon,
      iconActive: mushroomEditorIconActive,
      path: "/mushroom-editor",
      label: "Mushroom Editor",
      component: MushroomEditorPage,
    },
    {
      icon: insectIcon,
      iconActive: insectIconActive,
      path: "/insect",
      label: "Insect",
      component: InsectPage,
    },
    {
      icon: insectEditorIcon,
      iconActive: insectEditorIconActive,
      path: "/insect-editor",
      label: "Insect Editor",
      component: InsectEditorPage,
    },

    {
      icon: totalStorageIcon,
      iconActive: totalStorageIconActive,
      path: "/total-storage",
      label: "Total Storage",
      component: TotalStoragePage,
    },
    {
      icon: errorLogIcon,
      iconActive: errorLogIconActive,
      path: "/error-log",
      label: "Error Log",
      component: ErrorLogPage,
    },
    {
      icon: errorLogIcon,
      iconActive: errorLogIconActive,
      path: "/sync-log",
      label: "Sync Log",
      component: SyncLogPage,
    },
    {
      icon: usersIcon,
      iconActive: usersIconActive,
      path: "/users",
      label: "Users",
      component: UsersPage,
    },
    {
      icon: deviceIcon,
      iconActive: deviceIconActive,
      path: "/device",
      label: "Device",
      component: DevicePage,
    },
    {
      icon: editorIcon,
      iconActive: editorIconActive,
      path: "/editor",
      label: "Editor",
      component: EditorPage,
    },
    {
      icon: feedbackIcon,
      iconActive: feedbackIconActive,
      path: "/feedback",
      label: "Feedback",
      component: FeedbackPage,
    },
    {
      icon: wishListIcon,
      iconActive: wishListIconActive,
      path: "/wishlist",
      label: "Wish List",
      component: WishListPage,
    },

    {
      icon: jobCrawlIcon,
      iconActive: jobCrawlIconActive,
      path: "/job-crawl",
      label: "Job Crawl",
      component: JobCrawlPage,
    },

    {
      icon: chatIcon,
      iconActive: chatIconActive,
      path: "/faq",
      label: "FAQ",
      component: FaqPage,
    },
  ];
  if (profile?.roles && profile?.roles.includes('editor')) {
    menuList = [
      {
        icon: needPlantCareIcon,
        iconActive: needPlantCareIconActive,
        path: "/need-plant-care",
        label: "Need Plant Care",
        component: NeedPlantCarePage,
      },
      {
        icon: diagnoseEditorIcon,
        iconActive: diagnoseEditorIconActive,
        path: "/diagnose-editor",
        label: "Diagnose Editor",
        component: DiagnoseEditorPage,
      },
      {
        icon: toxicIcon,
        iconActive: toxicIconActive,
        path: "/toxic-editor",
        label: "Toxic Editor",
        component: ToxicEditorPage,
      },
      {
        icon: mushroomEditorIcon,
        iconActive: mushroomEditorIconActive,
        path: "/mushroom-editor",
        label: "Mushroom Editor",
        component: MushroomEditorPage,
      },
      {
        icon: insectEditorIcon,
        iconActive: insectEditorIconActive,
        path: "/insect-editor",
        label: "Insect Editor",
        component: InsectEditorPage,
      },
    ]
  }
  return menuList;
}