import { createSlice } from "@reduxjs/toolkit";

const initCategoryState = {
  categoryList: [],
  countCategory: 0,
  listLoading: false,
  actionsLoading: false,
  error: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const categorySlice = createSlice({
  name: "category",
  initialState: initCategoryState,
  reducers: {
    catchError: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    categoryFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.categoryList = data.items || [];

      if (data.objects) {
        state.countCategory = data.objects.total;
      } else if (data.total) {
        state.countCategory = data.total;
      } else {
        state.countCategory = 0;
      }
    },
  },
});
